import baliImg from "../assets/img/bali.jpg";

export const Data = [{
    id: 1,
    image: baliImg,
    title: 'Bali Island',
    location: 'Indonesia',
    grade: 'Cultural Relax',
    fees: '$500',
    description: 'Indonesian Island'
}, {
    id: 2,
    image: baliImg,
    title: 'Bali Island',
    location: 'Indonesia',
    grade: 'Cultural Relax',
    fees: '$500',
    description: 'Indonesian Island'
}, {
    id: 3,
    image: baliImg,
    title: 'Bali Island',
    location: 'Indonesia',
    grade: 'Cultural Relax',
    fees: '$500',
    description: 'Indonesian Island'
}, {
    id: 4,
    image: baliImg,
    title: 'Bali Island',
    location: 'Indonesia',
    grade: 'Cultural Relax',
    fees: '$500',
    description: 'Indonesian Island'
}, {
    id: 5,
    image: baliImg,
    title: 'Bali Island',
    location: 'Indonesia',
    grade: 'Cultural Relax',
    fees: '$500',
    description: 'Indonesian Island'
}, {
    id: 6,
    image: baliImg,
    title: 'Bali Island',
    location: 'Indonesia',
    grade: 'Cultural Relax',
    fees: '$500',
    description: 'Indonesian Island'
}]